/* Base styles */
:root {
  --primary-color: #EEF272;
  --secondary-color: #131313;
  --background-color: #000000;
  --whiteText-color: #FEFDEE;
}


* {
  margin: 0;
  padding: 0;
}



#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



/* App Container */

.app {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  padding-bottom: 20px;
}

.navbar-logo {
  width: 150px;
  height: auto;
  margin: 20px;
}

/* HomePage Styles */

.homepage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 1.25rem;
  flex-direction: row;
}

.text-container {
  margin-right: 2rem;
  color: var(--secondary-color)
}


.main-title {
  font-family: passion one;
  font-size: 4rem;
  text-align: left;
  margin-bottom: 1rem;
}

.sub-title {

  margin-bottom: 1rem;
}

.video-container {
  width: 45rem;
  height: auto;
}

.get-app-button {
  margin-top: 20px;
  padding: 12px 32px;
  background-color: var(--secondary-color);
  color: var(--whiteText-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.get-app-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.download-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.download-buttons button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

/* About Page Styles */

.about-subhead {
  font-family: passion one;
  font-size: 2rem;
  text-align: left;
  font-weight: 100;
}

/* Form Styles */
.event-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  width: 90%;
  /* Added for responsiveness */
  max-width: 800px;
  /* Added to limit width on large screens */
  padding: 20px;
  /* Added padding */
}

.event-form input,
.event-form select,
.event-form textarea {
  padding: 15px;
  border-radius: 4px;
  border: none;
  width: 100%;
}

.event-date {
  display: flex;
  gap: 10px;
  /* Added gap */
  flex-wrap: wrap;
  /* Added for responsiveness */
}


.form-buttons {
  font-family: "passion one" !important;
  font-size: 15px !important;
  width: 200px !important;
  margin: 20px auto !important;
  display: block !important;
  background-color: var(--secondary-color) !important;
  color: var(--whiteText-color) !important;
}

.form-buttons:hover {
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

.TextField .MuiInputBase-input {
  color: var(--secondary-color);
  background-color: white;
  border-radius: 5px;
}

/* Policy Styles */
.policy-container {
  padding: 20px;
  margin-bottom: 15%;
  width: 90%;
  /* Changed from 50% for better mobile view */
  max-width: 800px;
  /* Added max-width */
}

.terms-top-header {
  color: var(--secondary-color);
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  /* Added for better spacing */
  font-size: 2rem;
  /* Added for better hierarchy */
}

.terms-header {
  color: var(--secondary-color);
  padding: 15px;
  width: 100%;
  font-size: 1.5rem;
  /* Added for better hierarchy */
  margin-top: 20px;
  /* Added for better spacing */
}

.terms-body-text {
  color: var(--secondary-color);
  padding: 15px;
  width: 100%;
  line-height: 1.6;
  /* Added for better readability */
}


/* Footer Styles */
.footer {
  width: 100%;
}

.footer-divider {
  width: 90%;
  /* Match the width of the content */
  margin: 0 auto;
  /* Center the divider */
}

.footer-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  /* Match the width of the divider */
  margin: 0 auto;
  /* Center the content */
  padding: 20px 0;
  /* Add vertical padding */
}

.footer-social-media {
  display: flex;

}

.footer-links {
  display: flex;
}

.footer-link {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 1rem;
  transition: opacity 0.3s ease;
  margin: 0 10px;
}

.footer-link:hover {
  opacity: 0.7;
}

.footer-copyright {
  text-align: left;
  margin: 0;
}

.footer-right-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app {
    font-size: calc(8px + 2vmin);
    flex-direction: column;
    /* Stack items vertically on smaller screens */
    align-items: center;
    /* Center items */
  }

  .navbar-logo {
    width: 100px;
    margin: 10px;
  }

  .homepage-container {
    flex-direction: column;
    height: 130vh;
    padding: 1rem;
    width: 95%;
    justify-content: center;
  }

  .main-title {
    font-size: 3rem;
  }

  .sub-title {
    font-size: 1rem;
  }

  .video-container {
    width: 100%;
    margin: 1rem;
    margin-left: 2rem;

  }

  .event-form {
    width: 95%;
    padding: 10px;
  }

  .footer {
    height: auto;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .policy-container {
    width: 95%;
    margin-bottom: 120px;
    /* Increased for mobile footer */
  }

  .footer-copyright {
    font-size: 10px;
    /* Smaller font size for mobile */
  }

  .footer-items {
    flex-direction: column;
    gap: 20px;
  }

  .footer-right-content {
    flex-direction: column;
    align-items: center;
  }
}

/* Added tablet breakpoint */
@media (min-width: 769px) and (max-width: 1024px) {
  .policy-container {
    width: 80%;
  }

  .event-form {
    width: 80%;
  }

  .footer-items {
    flex-direction: row;
    /* Arrange items in a row on tablet */
  }

  .footer-link {
    font-size: 11px;
    /* Adjust font size for tablet */
  }

  .footer-copyright {
    font-size: 11px;
    /* Slightly larger for tablets */
  }
}

/* Added larger screens */
@media (min-width: 1025px) {
  .event-form {
    padding: 30px;
  }

  .footer-copyright {
    font-size: 12px;
    /* Default size for larger screens */
  }
}